* {
    margin: 0;
    padding: 0;
}

/* Common Style  */

.App {
    background-color: white;
}

body {
    overflow-x: hidden;
}

.min-height {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
}

@media only screen and (max-width: 767px)  {
    .min-height {
        display: block;
        flex-direction: column;
        height: 100%;
        min-height: 0;
    }
}

.heading-text {
    font-family: 'Times New Roman', Times, serif;
}

.button {
    background-color: #cea427;
    padding: 2px;
    border-radius: 5px;
    border: none;
}

input,
button,
select,
optgroup,
textarea {
    font-family: 'Times New Roman', Times, serif;
    line-clamp: 2px;
}

/*** About ***/

.about {
    color: #cea427;
}

/* Footer */

.copyright {
    background-color: #cea427;
}

@media (max-width: 750px) {
    .footer {
        /* margin-left: 10px; */
        margin-top: 20px;
    }
}

@media (min-width: 750px) {
    .footer {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

.facebook:hover {
    color: blue
}

.twitter:hover {
    color: lightskyblue
}

.whatsapp:hover {
    color: #25d366
}

.linkedln:hover {
    color: rgba(0, 0, 255, 0.511)
}

.youtube:hover {
    color: red
}

.instagram:hover {
    color: rgb(223, 52, 52)
}

/* Header */

.my-custom-nav {
    font-family: 'Times New Roman', Times, serif;
}

@media (min-width: 750px) {
    .my-custom-nav {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-right: 30px;
        margin-left: 100px;
        gap: 30px;
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }

    .my-custom-nav:active {
        border-bottom: #000;
    }
}

@media (max-width: 750px) {
    .my-custom-nav {
        margin-top: 0px;
        margin-bottom: 40px;
    }
}

/* Dropdown in header*/

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
    background-color: transparent !important;
    color: black !important;
}

/* Day-Rental */

.home-input {
    border: none !important;
    border: none;
    outline: none;
    box-shadow: none;
}

.home-input:active {
    background-color: none !important;
}

.home-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

/*Date Picker */

.custom-datepicker {
    width: 200px;
}

.custom-datepicker input {
    height: 35px;
}

@media (max-width: 768px) {
    .react-datepicker {
        margin: 0 auto;
        margin-left: 30px;
    }
}

/* Home */

.home-page {
    background-color: #dfe4e7e3;
}

.position-absolute {
    margin-top: 150px;
}

/* Outstation Tabs*/

.outstation-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    width: 150px;
    border-radius: 5px;
}

.tab-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Search Page*/

.car_image {
    display: block;
    width: 100%;
    height: 120px;
    object-fit: contain;
}

.cars_data {
    margin-right: 5px;
}

.cars-text {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.car-container {
    position: relative;
    object-fit: cover;
}

.discount-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #cea427;
    padding: 5px;
    text-align: center;
    font-size: 14px;
}

.car_image_container {
    position: relative;
    overflow: hidden;
}

/* Traveller */

.traveller-page {
    align-items: center;
    justify-content: center;
}

.booking-success {
    align-items: center;
    justify-content: center;
    height: 90vh;
}

/* My Bookings  (check it return)*/

.my-bookings {
    color: #343a40;
}

.booking-info {
    gap: 1rem;
}

.booking-info>div {
    flex: 1;
    margin-bottom: 1rem;
}

.booking-details-card {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}

.booking-details-card p {
    margin-bottom: 0.5rem;
}

.my-bookings .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.my-bookings .btn-outline-info:hover {
    background-color: #17a2b8;
    color: #ffffff;
}

@media (max-width: 768px) {
    .booking-info {
        flex-direction: column;
    }

    .my-bookings .booking-details-card {
        margin-bottom: 1rem;
    }
}

/* Coupen */

.form-check-input[type=checkbox] {
    border-radius: 0.25em;
    border-color: #000;
}

.form-check-input[type=checkbox]:checked {
    background-color: #cea427;
    border-color: #cea427;
}

.pac-container:after {
    display: none;
}

.coupon {
    padding: 0.1rem 0.75rem !important;
}